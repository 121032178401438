import React, { useEffect, useState, useContext } from "react";
import bellIcon from "../src/assets/bell_icon.png";
import "./NotificationAlert.css";
import { useCaregiver } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const NotificationAlert = ({ isDropdownVisible }) => {
  const [alerts, setAlerts] = useState([]);
  const [incompleteActivities, setIncompleteActivities] = useState([]);
  const { selectedClient, setSelectedClient, clientName, setClientName, fetchClients, clients, setClients, fetchClientName, fetchActivities, activities, setActivities } = useContext(ClientContext);
  const { caregiverId, isCaregiver } = useCaregiver();


  useEffect(() => {
    const fetchIncompleteActivities = async () => {
      try {
        const response = await fetch(`${BASE_URL}/incomplete-activities/${caregiverId}` , {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
        });
        if (response.ok) {
          const data = await response.json();
          setIncompleteActivities(data);
        } else {
          console.error("Failed to fetch incomplete activities");
        }
      } catch (error) {
        console.error("Error fetching incomplete activities:", error);
      }
    };

    fetchIncompleteActivities();
  }, [selectedClient, caregiverId]);

  useEffect(() => {
    const now = new Date();
    const startOfToday = new Date(now);
    startOfToday.setHours(0, 0, 0, 0);
    const startOfYesterday = new Date(startOfToday);
    startOfYesterday.setDate(startOfYesterday.getDate() - 1);

    const pastDueActivities = incompleteActivities.filter((activity) => {
      const activityEndTime = new Date(activity.end_time);
      return (
        (activityEndTime >= startOfYesterday && activityEndTime < startOfToday) ||
        (activityEndTime >= startOfToday && activityEndTime <= now)
      ) && activity.event_state !== "completed";
    });

    const newAlerts = pastDueActivities.map((activity) => {
      return {
        message: `${activity.client_name} didn't complete ${activity.activity_title}.`,
        type: "error",
      };
    });

    setAlerts(newAlerts);
  }, [incompleteActivities]);

  return (
    <div className="notification-alert">
      <div className="notification-icon">
        <span className="notification-count">{alerts.length}</span>
        <img src={bellIcon} alt="Notifications" />
      </div>
      {isDropdownVisible && alerts.length > 0 && (
        <div className="notification-dropdown">
          {alerts.map((alert, index) => (
            <div key={index} className={`notification-item ${alert.type}`}>
              {alert.message}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationAlert;
