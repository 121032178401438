import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import "./ClientPortal.css";
import { useLocation, useNavigate } from "react-router-dom";
import TaskModal from "./TaskModal";
import EventModal from "./EventModal";
import { useCaregiver } from "./CaregiverContext";
require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;

function ClientPortal() {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [showAll, setShowAll] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isCaregiver = location.state?.isCaregiver || false;
  const { caregiverId } = useCaregiver();

  const generateTimeSlots = () => {
    let slots = [];
    for (let hour = 0; hour < 24; hour++) {
      slots.push(`${hour.toString().padStart(2, "0")}:00`);
    }
    return slots;
  };

  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  const currentTimePosition = currentHour * 60 + currentMinute; // Again, assuming 60px per hour

  const timeSlots = generateTimeSlots();

  useEffect(() => {
    // Fetch tasks and events when the component mounts
    fetchClientActivities();
  }, []);

  useEffect(() => {
    if (showAll) {
      // setFilteredTasks(tasks);
      setFilteredEvents(events);
    } else {
      filterActivitiesByDay();
    }
    //removed tasks from array below
  }, [events, showAll]);

  const fetchClientActivities = async () => {
    try {
      const response = await fetch(`${BASE_URL}/client-activities`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client activities");
      }
      const data = await response.json();
      // setTasks(data.tasks);
      setEvents(data.events);
    } catch (error) {
      console.error("Error fetching client activities:", error);
    }
  };

  // const handleCompleteButtonClick = async (id, type, currentState) => {
  //     try {
  // const newState = currentState === 'complete' || currentState === 'completed' ? 'incomplete' : 'complete';
  //         const response = await fetch(`${BASE_URL}/activities/${id}`, {
  //             method: 'PATCH',
  //             headers: {
  //                'Content-Type': 'application/json',
  //                'Authorization': 'Bearer ' + localStorage.getItem('token'),
  //             },
  //             body: JSON.stringify({ event_state: newState })
  //         });

  //         if (!response.ok) {
  //             throw new Error('Failed to toggle item completion state');
  //         }

  //         fetchClientActivities(); // Refetch tasks and events after toggling completion state
  //     } catch (error) {
  //         console.error('Error toggling item completion state:', error);
  //     }
  // };

  const filterActivitiesByDay = () => {
    const currentDate = new Date();
    // const filteredTaskList = tasks.filter(task => {
    // const taskDate = new Date(task.start_time);
    // return taskDate.getDate() === currentDate.getDate() && taskDate.getMonth() === currentDate.getMonth() && taskDate.getFullYear() === currentDate.getFullYear();
    // });
    const filteredEventList = events.filter((event) => {
      const eventDate = new Date(event.start_time);
      return (
        eventDate.getDate() === currentDate.getDate() &&
        eventDate.getMonth() === currentDate.getMonth() &&
        eventDate.getFullYear() === currentDate.getFullYear()
      );
    });
    // setFilteredTasks(filteredTaskList);
    setFilteredEvents(filteredEventList);
  };

  const handleShowAllClick = () => {
    setShowAll(true); // Set showAll to true when "Show All Activities" is clicked
  };

  const handleShowTodayClick = () => {
    const currentDate = new Date();
    // const filteredTaskList = tasks.filter(task => {
    // const taskDate = new Date(task.start_time);
    // return taskDate.getDate() === currentDate.getDate() && taskDate.getMonth() === currentDate.getMonth() && taskDate.getFullYear() === currentDate.getFullYear();
    // });
    const filteredEventList = events.filter((event) => {
      const eventDate = new Date(event.start_time);
      return (
        eventDate.getDate() === currentDate.getDate() &&
        eventDate.getMonth() === currentDate.getMonth() &&
        eventDate.getFullYear() === currentDate.getFullYear()
      );
    });
    // setFilteredTasks(filteredTaskList);
    setFilteredEvents(filteredEventList);
    setShowAll(false); // Set showAll to false when "Show Today's Activities" is clicked
  };

  const openModal = (event) => {
    // setSelectedTask(task);
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setModalOpen(false);
  };
  const handleHomepageClick = () => {
    navigate("../select-client-homepage", { state: { caregiverId } });
  };
  return (
    <div className="ClientPortal">
      <h2 className="ClientPortal-heading">Client Portal</h2>
      <div className="ClientActivities">
        {isCaregiver && (
          <button
            className="home-page-button"
            onClick={() => handleHomepageClick()}
          >
            Home Page
          </button>
        )}
        <h3>Events</h3>
        <ul>
          {filteredEvents.map((event) => (
            <li
              key={event.id}
              className={event.event_state === "complete" ? "complete" : ""}
            >
              <div className="event-details">
                <div className="event-title">
                  <strong>{event.title}</strong>
                </div>
                <div className="event-description">{event.description}</div>
                <div className="event-datetime">
                  Start Time: {new Date(event.start_time).toISOString()}
                </div>
                <div className="event-datetime">
                  End Time: {new Date(event.end_time).toISOString()}
                </div>
                {Array.isArray(event.steps) && event.steps.length > 0 && (
                  <button onClick={() => openModal(event)}>
                    View Event Steps
                  </button>
                )}
              </div>
              {/* <button onClick={() => handleCompleteButtonClick(event.id, 'event', event.event_state)}>
                                {event.event_state === 'complete' ? 'Undo' : 'Complete'}
                            </button> */}
            </li>
          ))}
        </ul>
        {/*<h3>Tasks</h3>
                <ul>
                    {filteredTasks.map(task => (
                        <li key={task.id} className={task.event_state === 'complete' ? 'complete' : ''}>
                            <div className="task-details">
                                <div className="task-title"><strong>{task.title}</strong></div>
                                {task.description && <div className="task-description">{task.description}</div>}
                                {task.steps && task.steps.length > 0 && (
                                    <button onClick={() => openModal(task)}>View Steps</button>
                                )}
                            </div>
                            <button onClick={() => handleCompleteButtonClick(task.id, 'task', task.event_state)}>
                                {task.event_state === 'complete' ? 'Undo' : 'Complete'}
                            </button>
                        </li>
                    ))}
                </ul> */}
      </div>
      <EventModal
        isOpen={modalOpen}
        closeModal={closeModal}
        selectedEvent={selectedEvent}
        isCareGiver={false}
      />
    </div>
  );
}

export default withAuthenticationRequired(ClientPortal);
