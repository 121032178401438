import React, { useState } from 'react';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import './InviteUserPage.css';

const InviteUserPage = ({ userType }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/invite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({
          name: firstName + ' ' + lastName,
          first_name: firstName,
          last_name: lastName,
          email,
          user_type: userType,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert("User invited"); // Notify success
        setFirstName('');
        setLastName('');
        setEmail('');
      } else {
        alert('Error inviting: ' + data.message); // Notify error
      }
    } catch (error) {
      alert('Error inviting: ' + error.message); // Notify error
    }
  };

  function toTitleCase(str) {
    return str.replace(/_/g, " ").replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  return (
    <div className="modal-overlay">
      <div className="modal" style={{display: 'inline-block' }}>
        <h1>Invite User as {toTitleCase(userType)}</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label htmlFor="name">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="name">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="addStepButton">Invite</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default withAuthenticationRequired(InviteUserPage);
