// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginButtonUnique {
  padding: 10px 20px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .loginButtonUnique {
    padding: 8px 16px;
  }

  .loginLogoUnique img {
    width: 100px;
  }
}

@media (min-width: 601px) {
  .loginButtonUnique {
    padding: 12px 24px;
  }

  .loginLogoUnique img {
    width: 200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA,sBAAsB;AACtB;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".loginButtonUnique {\n  padding: 10px 20px;\n}\n\n/* Responsive styles */\n@media (max-width: 600px) {\n  .loginButtonUnique {\n    padding: 8px 16px;\n  }\n\n  .loginLogoUnique img {\n    width: 100px;\n  }\n}\n\n@media (min-width: 601px) {\n  .loginButtonUnique {\n    padding: 12px 24px;\n  }\n\n  .loginLogoUnique img {\n    width: 200px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
