import React, { useState, useEffect, useContext } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import "./SelectClientHomepage.css";
import { useCaregiver } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";
import homeScreenSplash from "../src/assets/homescreen-splash.jpg";

require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;

function SelectClientHomepage() {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  const {
    selectedClient,
    setSelectedClient,
    handleClientChange,
    clients,
    setClients,
  } = useContext(ClientContext);
  const [activities, setActivities] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [selectedCaregiver, setSelectedCaregiver] = useState(
    localStorage.getItem("selectedCaregiver") || "",
  );
  // const { caregiverId, isCaregiver } = useCaregiver();
  const caregiverId = localStorage.getItem("caregiverId");
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedClient) {
      const fetchActivities = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/clients/${selectedClient}/activities`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
          });
          if (response.ok) {
            const data = await response.json();
            console.log("schmp data", data);
            setActivities(data);
            navigate(`/calendar/${selectedClient}`, {
              state: { isCaregiver: true },
            });
          } else {
            console.error("Failed to fetch activities for client");
          }
        } catch (error) {
          console.error("Error fetching activities for client:", error);
        }
      };

      fetchActivities();
    }
  }, [selectedClient]);

  useEffect(() => {
    const fetchClients = async () => {
      let url = `${BASE_URL}/caregivers/${caregiverId}/clients`
      if (localStorage.getItem('isSuperAdmin') == 'true') {
        url = `${BASE_URL}/clients`
      }
       try {
        const response = await fetch(
          url, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
          });
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          setClients(data);
        } else {
          console.error("Failed to fetch clients for caregiver");
        }
      } catch (error) {
        console.error("Error fetching clients for caregiver:", error);
      }
    };

    if (caregiverId) {
      fetchClients();
    }
  }, [caregiverId, setClients]);

  useEffect(() => {
    const fetchCaregivers = async () => {
      let url = `${BASE_URL}/caregivers?user_id=${localStorage.getItem("caregiverId")}`;
      if (localStorage.getItem('isSuperAdmin')) {
        url = `${BASE_URL}/caregivers`
      }
      try {
        const response = await fetch(
          url, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
          });
        if (response.ok) {
          const data = await response.json();
          setCaregivers(data);
        } else {
          console.error("Failed to fetch caregivers");
        }
      } catch (error) {
        console.error("Error fetching caregivers", error);
      }
    };

    if (localStorage.getItem('isSuperAdmin') || localStorage.getItem('isAdmin')) {
      fetchCaregivers();
    }
  }, [caregiverId, setCaregivers]);

  const handleCaregiverChange = (e) => {
    const caregiverId = e.target.value;
    if (caregiverId == 'create') {
       window.location.href = '/add-caregiver'
      return
    }
    setSelectedCaregiver(caregiverId);
    localStorage.setItem("selectedCaregiver", caregiverId);
  };

  useEffect(() => {
    const fetchCaregivers = async () => {
      let url = `${BASE_URL}/caregivers?user_id=${localStorage.getItem("caregiverId")}`;
      if (localStorage.getItem('isSuperAdmin')) {
        url = `${BASE_URL}/caregivers`
      }
      try {
        const response = await fetch(
          url, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
          });
        if (response.ok) {
          const data = await response.json();
          setCaregivers(data);
        } else {
          console.error("Failed to fetch caregivers");
        }
      } catch (error) {
        console.error("Error fetching caregivers", error);
      }
    };

    if (localStorage.getItem('isSuperAdmin') || localStorage.getItem('isAdmin')) {
      fetchCaregivers();
    }
  }, [caregiverId, setCaregivers]);
  
  return (
    <div className="home-page">
      <header className="header">
        <div className="inner">
          <div className="Logo">
            <img src="/images/Compass-Care_White.png" alt="Logo" style={{ width: '200px' }} />
          </div>
          <div className="sch-client-selector">
            <select defaultValue={1} value={selectedClient} onChange={handleClientChange}>
              <option value="">Select a client</option>
              { localStorage.getItem('isSuperAdmin') ? (<option value="create">--- Create new client ---</option>) : (null) }
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>
          { localStorage.getItem('isSuperAdmin') || localStorage.getItem('isAdmin') ?
          (<div className="sch-client-selector">
            <select defaultValue={selectedCaregiver} onChange={handleCaregiverChange}>
              <option value="">Select a caregiver</option>
              { localStorage.getItem('isAdmin') ? (<option value="create">--- Create new caregiver ---</option>) : (null) }
              {caregivers.map((caregiver) => (
                <option key={caregiver.id} value={caregiver.id}>
                  {caregiver.name}
                </option>
              ))}
            </select>
          </div>)
          : (null)}
        </div>
        <button className="btn-branding" style={{ width: "150px" }} onClick={(ev) => {
                ev.preventDefault();
                logout({ logoutParams: { returnTo: window.location.origin + '/login' } });
                localStorage.clear();
            }}>
              Log Out
        </button>
      </header>
      <main className="main-content">
        <img
          src={homeScreenSplash}
          alt="Home Screen Splash"
          className="splash-image"
        />
        <h1 className="intro">Compass Care</h1>
        <h1>Where care takes direction.</h1>
        {/* <h1>Compass Care</h1> */}
        {/* <div className="navigation-buttons"> */}
        {/* <button onClick={fetchContacts}>Client call lists</button> */}
        {/* Uncomment and adjust as needed */}
        {/* <button className='large-button' onClick={() => alert(`Track Client Activities: ${selectedClient}`)}>
          Track client activities
        </button> */}
        {/* </div> */}
      </main>
    </div>
  );
}

export default withAuthenticationRequired(SelectClientHomepage);