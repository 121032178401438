// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-alert {
  position: relative;
  display: inline-block;
}

.notification-icon {
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}

.notification-icon img {
  width: 24px; /* Adjust the width as needed */
  height: 24px; /* Adjust the height as needed */
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
}

.notification-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 1000;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.notification-item.error {
  color: red;
  font-size: 14px;
}

.notification-item:last-child {
  border-bottom: none;
}
`, "",{"version":3,"sources":["webpack://./src/NotificationAlert.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW,EAAE,+BAA+B;EAC5C,YAAY,EAAE,gCAAgC;AAChD;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,uBAAuB;EACvB,sBAAsB;EACtB,2CAA2C;EAC3C,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".notification-alert {\n  position: relative;\n  display: inline-block;\n}\n\n.notification-icon {\n  margin-right: 20px;\n  position: relative;\n  cursor: pointer;\n}\n\n.notification-icon img {\n  width: 24px; /* Adjust the width as needed */\n  height: 24px; /* Adjust the height as needed */\n}\n\n.notification-count {\n  position: absolute;\n  top: -5px;\n  right: -5px;\n  background-color: red;\n  color: white;\n  border-radius: 50%;\n  padding: 2px 5px;\n  font-size: 12px;\n}\n\n.notification-dropdown {\n  position: absolute;\n  top: 100%;\n  right: 0;\n  background-color: white;\n  border: 1px solid #ccc;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  width: 200px;\n  z-index: 1000;\n}\n\n.notification-item {\n  padding: 10px;\n  border-bottom: 1px solid #ccc;\n}\n\n.notification-item.error {\n  color: red;\n  font-size: 14px;\n}\n\n.notification-item:last-child {\n  border-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
