// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    width: 80%;
    height: 80%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .modal-content h3,
  .modal-content p {
    text-align: left;
    margin-left: 0px;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .input-container label {
    width: 20%;
    margin-right: 25px;
    text-align: right;
  }
  
  .input-container input,
  .input-container textarea {
    width: 70%;
    margin-left: auto;
  }
  
  .addStepButton {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/admin/AddCaregiverPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;;IAEE,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;;IAEE,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .modal {\n    width: 80%;\n    height: 80%;\n    background-color: #fff;\n    padding: 20px;\n    box-sizing: border-box;\n    overflow-y: auto;\n  }\n  \n  .modal-content h3,\n  .modal-content p {\n    text-align: left;\n    margin-left: 0px;\n  }\n  \n  .input-container {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n  }\n  \n  .input-container label {\n    width: 20%;\n    margin-right: 25px;\n    text-align: right;\n  }\n  \n  .input-container input,\n  .input-container textarea {\n    width: 70%;\n    margin-left: auto;\n  }\n  \n  .addStepButton {\n    background-color: #4caf50;\n    color: white;\n    border: none;\n    padding: 5px 10px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
