import React, { useState, useEffect, useContext } from "react";
import { useCaregiver } from "./CaregiverContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ClientContext } from "./ClientContext";
import Layout from "./Layout";
import "./CallPage.css";
import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Container, Button, Stack, Box } from '@mui/material';
import { toBePartiallyChecked } from "@testing-library/jest-dom/matchers";


require("dotenv").config();

const CallPage = () => {
  const { clientId } = useParams();
  const { caregiverId, isCaregiver } = useCaregiver();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedClient,
    setSelectedClient,
    handleClientChange,
    clients,
    setClients,
    fetchClientName,
    clientName,
    setContacts,
    fetchContacts,
  } = useContext(ClientContext);

  async function joinZoomMeeting() {

    var payload = {
      topic: 'topic',
      userName: 'UserA',
      role: 1,
    }
    var response
    try {
      response = await fetch(`${process.env.REACT_APP_BASE_URL}/zoomConfig`, {
          method: "POST",
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': "Application/JSON"
          },
          body: JSON.stringify(payload),
        })     
        .then(response => response.json())
        .then(response => {
          var config = {
            videoSDKJWT: '',
            sessionName: 'topic',
            userName: 'UserA',
            sessionPasscode: '',
            features: ['preview', 'video', 'audio', 'share', 'chat', 'users', 'settings'],
            options: { init: {}, audio: {}, video: {enforceMultipleVideos: true}, share: {}},
          }
          config.videoSDKJWT = response.zoomConfig
          try {
            var sessionContainer = document.getElementById('sessionContainer')
            uitoolkit.joinSession(sessionContainer, config)
            uitoolkit.onSessionJoined(sessionJoined)
            uitoolkit.onSessionClosed(sessionClosed)

          } catch (error) {
            console.log(error)
          }
        });
    }
    catch (error) {
      console.error("Error setting up Zoom :", error);
    }
  }

  var sessionJoined = (() => {
    console.log('session joined')
  })
  
  var sessionClosed = (() => {
    console.log('session closed')
  })

  useEffect(() => {
    const clientFromState = location.state?.selectedClient || clientId;
    if (clientFromState) {
      setSelectedClient(selectedClient);
      fetchClientName(selectedClient);
    }
  }, [clientId, location.state?.selectedClient]);

  // Set selected client from URL parameter
  useEffect(() => {
    if (clientId) {
      setSelectedClient(clientId);
    }
  },

  [clientId, setSelectedClient]);

  return (
    <Layout clients={clients}>
      <Container maxWidth="false">
        <Box display="flex"
               justifyContent="center"
               alignItems="center" sx={{ bgcolor: '#1E1E1E', height: '300px' }}>
          <Stack direction="row" spacing={5}>
            <Stack spacing={2}>
              <h2 class="call-title">Call {clientName}</h2>
              <h2 class="call-title">You can also view and run reports on past calls.</h2>
            </Stack>
            <Button class="call-button" variant="contained" onClick={joinZoomMeeting}>Call Now</Button>
          </Stack>
        </Box>
      </Container>
      <div id='sessionContainer'></div>
      </Layout>
     );
};
export default withAuthenticationRequired(CallPage);
