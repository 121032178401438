import React, { useState } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useCaregiver } from "../CaregiverContext";
import './AddCaregiverPage.css';

const AddCaregiverPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const { caregiverId, setCaregiverId } = useCaregiver();
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const name = firstName + ' ' + lastName;
      const userType = 'caregiver';

      const params = {
        email,
        password: (Math.random() + 1).toString(36),
        name: name,
        given_name: firstName,
        family_name: lastName,
        connection: "Username-Password-Authentication",
        user_metadata: {},
      };

      if (userType) {
        params.user_metadata.user_type = userType;
      }

      params.user_metadata.register_data = { email, name, first_name: firstName, last_name: lastName, caregiver_id: caregiverId, created_by: localStorage.getItem('caregiverId') }

      const response = await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(params),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Caregiver created"); // Notify success
        setFirstName('');
        setLastName('');
        setEmail('');
        setCaregiverId('');
      } else {
        alert('Error adding client: ' + data.message); // Notify error
      }
    } catch (error) {
      alert('Error adding client: ' + error.message); // Notify error
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal" style={{display: 'inline-block' }}>
        <h1>Add New Caregiver</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="addStepButton">Save</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default withAuthenticationRequired(AddCaregiverPage);
