import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ClientPortal from "./ClientPortal";
import ContactsPage from "./ContactsPage";
import Login from "./Login";
import SelectClientHomepage from "./SelectClientHomepage";
import InviteUserPage from "./admin/InviteUserPage";
import AddClientPage from "./AddClientPage";
import AddCaregiverPage from "./admin/AddCaregiverPage";
import RegisterUserPage from "./auth/RegisterUserPage";
import TermsPage from "./TermsPage";
import { CaregiverProvider } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";
import { ClientProvider } from "./ClientContext";
import ClientCalendar from "./ClientCalendar";
import TrackActivitiesPage from "./TrackActivitiesPage";
import CompassIcon from "../src/assets/CompassIcon.png";
import "./App.css";
import CallPage from "./CallPage"

import axios from 'axios';

function App() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const [loggedIn, setLoggedIn] = useState(true);
  const [userId, setUserId] = useState(null);
  // const [clients, setClients] = useContext(ClientContext)
  const [selectedClient, setSelectedClient] = useState("");
  
  
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((registration) => {
          console.log("Registration successful");
        })
        .catch((error) => {
          console.log("Service worker registration failed");
        });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      localStorage.setItem('authuser', JSON.stringify(user));

      getAccessToken();

      if (localStorage.getItem('user') == null) {
        setTimeout(() => {
            const token = localStorage.getItem('token');
            loginUser(token, user.sub, user.email).then((user_data) => {
                let user_type = user_data.user_type.toLowerCase();
                user_data.picture = user.picture;
                user_data.user_type = user_type.match("caregiver") ? 'caregiver' : 'client';
                user_data.is_admin = user_type.match('admin') ? true : false;
                localStorage.setItem('userIsTermsAccepted', user_data.is_terms_accepted);
                localStorage.setItem('user', JSON.stringify(user_data));

              if (user_type.match("caregiver")) {
                localStorage.setItem("caregiverId", user_data.id)
                localStorage.setItem('isCaregiver', true)
              } else if (user_type.match("client")) {
                localStorage.setItem('isCaregiver', false)
              }

              if (user_type.match("caregiver_admin")) {
                localStorage.setItem('isAdmin', true)
              }

              if (user_type.match("super_admin")) {
                localStorage.setItem('isSuperAdmin', true)
              }
            })
        }, 1500);
      }
    }
  });

  async function getAccessToken() {
    if (localStorage.getItem('token') != null) { return; }

    // TODO: Convert to fetch then remove axios
    await axios.post('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/oauth/token', {
        client_id: process.env.REACT_APP_AUTH0_BE_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH0_BE_CLIENT_SECRET,
        audience: "https://" + process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        grant_type: "client_credentials"
    }, {
        headers: { 'content-type': 'application/json' },
    }).then(response => {
        localStorage.setItem('authdata', JSON.stringify(response.data));
        localStorage.setItem('token', response.data.access_token); // TODO: Check how to refresh when expired
    });
  }

  async function loginUser(token, uid, email) {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "mode": "CORS",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({ username: uid, email }),
      });
      if (response.ok) {
        const data = await response.json();
        if (data.user) {
          localStorage.setItem("caregiverId", data.user.id);
          localStorage.setItem('isCaregiver', true);
        }
        return data.user;
      }

      return {};
}

  const handleClientChange = (clientId) => {
    setSelectedClient(clientId);
  };

  const handleLogin = (userId) => {
    setLoggedIn(true);
    setUserId(userId);
  };

  if (isLoading) {
    return (
      <div>Loading ...</div>
    )
  }
  
  if (process.env.REACT_APP_FEATURE_FLAG_TERMS_PAGE == 'true') {
    if (isAuthenticated &&
        localStorage.getItem('userIsTermsAccepted') != 'true' &&
        window.location.pathname != '/terms') {
      window.location.href = '/terms';
    }
  }

  return (
    <CaregiverProvider>
      <ClientProvider>
        <BrowserRouter>
          <div className="App">
            <div className="App-content">
              <Routes>
                <Route
                  path="/authorize"
                  element={
                    !isAuthenticated ? (<Login onLogin={handleLogin} />) : (<Navigate replace to="/select-client-homepage" />)
                  }
                />
                <Route
                  path="/login"
                  element={!isAuthenticated ? (<Login onLogin={handleLogin} />) : (<Navigate replace to="/select-client-homepage" />)}
                />
                <Route
                  path="/terms"
                  element={localStorage.getItem('userIsTermsAccepted') != 'true' ? (<TermsPage />) : (<Navigate replace to="/select-client-homepage" />)}
                />
                <Route
                  path="/client"
                  element={
                    loggedIn ? (
                      <ClientPortal />
                    ) : (
                      <Navigate replace to="/login" />
                    )
                  }
                />
                <Route path="/" element={ !isAuthenticated ? (<Navigate replace to="/login" />) : (<Navigate replace to="/select-client-homepage" />)} />
                <Route
                  path="/select-client-homepage"
                  element={
                    loggedIn ? (
                      <SelectClientHomepage userId={userId} />
                    ) : (
                      <Navigate replace to="/login" />
                    )
                  }
                />
                <Route
                  path="/calendar/:clientId"
                  element={
                    loggedIn ? (
                      <ClientCalendar
                        selectedClient={selectedClient}
                        handleClientChange={handleClientChange}
                      />
                    ) : (
                      <Navigate replace to="/login" />
                    )
                  }
                />
                <Route
                  path="/track-activities/:clientId"
                  element={
                    loggedIn ? (
                      <TrackActivitiesPage
                        selectedClient={selectedClient}
                        handleClientChange={handleClientChange}
                      />
                    ) : (
                      <Navigate replace to="/login" />
                    )
                  }
                />
                <Route
                  path="/contacts/:clientId"
                  element={
                    <ContactsPage
                      selectedClient={selectedClient}
                      handleClientChange={handleClientChange}
                    />
                  }
                />
                <Route
                  path="/add-client"
                  element={
                    localStorage.getItem('isSuperAdmin') == 'true' ? (<AddClientPage />) : (<Navigate replace to="/select-client-homepage" />)
                  }
                />
                <Route
                  path="/add-caregiver"
                  element={
                    localStorage.getItem('isAdmin') == 'true' ? (<AddCaregiverPage />) : (<Navigate replace to="/select-client-homepage" />)
                  }
                />
                <Route
                  path="/add-admin"
                  element={
                    localStorage.getItem('isSuperAdmin') == 'true' ? (<InviteUserPage userType="caregiver_admin" />) : (<Navigate replace to="/select-client-homepage" />)
                  }
                />
                <Route
                  path="/register"
                  element={
                    !isAuthenticated ? (<RegisterUserPage />) : (<Navigate replace to="/select-client-homepage" />)
                  }
                />
                <Route
                  path="/call/:clientId"
                  element={
                    <CallPage
                      selectedClient={selectedClient}
                      handleClientChange={handleClientChange}
                    />
                    }
                  />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </ClientProvider>
    </CaregiverProvider>
  );
}
export default App;