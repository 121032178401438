import React, { useEffect } from "react";
import "./TaskModal.css";

function TaskModal({ isOpen, closeModal, selectedTask }) {
  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.keyCode === 27 && isOpen) {
        closeModal();
      }
    };

    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".modal")) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEscapeKeyPress);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, closeModal]);

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal" style={{display: isOpen ? 'inline-block' : 'none' }}>
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <ul className="modal-list">
                {selectedTask.steps.map((step) => (
                  <li key={step.id}>
                    {step.title}: {step.content}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TaskModal;
