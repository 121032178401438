import React, { createContext, useState, useContext, useEffect } from "react";

const CaregiverContext = createContext();

export const useCaregiver = () => useContext(CaregiverContext);

export const CaregiverProvider = ({ children }) => {
  const [caregiverId, setCaregiverId] = useState(
    localStorage.getItem("caregiverId"),
  );
  const [isCaregiver, setIsCaregiver] = useState(false);
  useEffect(() => {
    if (caregiverId) {
      localStorage.setItem("caregiverId", caregiverId);
      setIsCaregiver(true);
    } else {
      setIsCaregiver(false);
    }
  }, [caregiverId]);
  // useEffect(() => {
  //   console.log('context caregiver id', caregiverId)
  // }, [caregiverId])
  return (
    <CaregiverContext.Provider
      value={{ caregiverId, setCaregiverId, isCaregiver, setIsCaregiver }}
    >
      {children}
    </CaregiverContext.Provider>
  );
};
