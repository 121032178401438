import React, { useState, useEffect, useContext } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useCaregiver } from "./CaregiverContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ClientContext } from "./ClientContext";
import "./ContactsPage.css";
import Layout from "./Layout";

require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ContactsPage = () => {
  const { caregiverId, isCaregiver } = useCaregiver();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedClient,
    setSelectedClient,
    handleClientChange,
    clients,
    setClients,
    contacts,
    setContacts,
    fetchContacts,
  } = useContext(ClientContext);
  const [selectedTab, setSelectedTab] = useState("regular");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactForm, setContactForm] = useState({
    id: null,
    name: "",
    email: "",
    phone: "",
    photo: "",
    status: "active",
    contactType: ["regular"],
  });
  const { clientId } = useParams();

  // Set selected client from URL parameter
  useEffect(() => {
    if (clientId) {
      setSelectedClient(clientId);
    }
  }, [clientId, setSelectedClient]);

  const fetchClients = async (caregiverId) => {
    try {
      const response = await fetch(
        `${BASE_URL}/caregivers/${caregiverId}/clients`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
      });
      if (response.ok) {
        const data = await response.json();
        setClients(data);
      } else {
        console.error("Failed to fetch clients for caregiver");
      }
    } catch (error) {
      console.error("Error fetching clients for caregiver:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "contactType") {
      setContactForm((prevForm) => {
        const newContactType = checked
          ? [...prevForm.contactType, value]
          : prevForm.contactType.filter((type) => type !== value);
        console.log(`Selected contact types: ${newContactType.join(", ")}`);
        return {
          ...prevForm,
          contactType: newContactType,
        };
      });
    } else {
      setContactForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  };
  
  const handleSaveContact = async () => {
    const url = contactForm.id
      ? `${BASE_URL}/contacts/${contactForm.id}`
      : `${BASE_URL}/contacts`;
    const method = contactForm.id ? "PUT" : "POST";
    const body = JSON.stringify({
      caregiverId,
      clientId: selectedClient,
      name: contactForm.name,
      phone: contactForm.phone,
      email: contactForm.email,
      photo: contactForm.photo,
      status: contactForm.status,
      contactType: contactForm.contactType,
    });

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body,
      });

      if (response.ok) {
        console.log("Contact saved successfully");
        closeModal();
      } else {
        console.error("Failed to save contact");
      }
    } catch (error) {
      console.error("Error saving contact:", error);
    }
  };

  const handleDeleteContact = async () => {
    if (!contactForm.id) return;

    try {
      const response = await fetch(
        `${BASE_URL}/contacts/${contactForm.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
        },
      );

      if (response.ok) {
        console.log("Contact deleted successfully");
        // await fetchContacts(selectedClient);
        closeModal();
      } else {
        console.error("Failed to delete contact");
      }
    } catch (error) {
      console.error("Error deleting contact:", error);
    }
  };

  const openModal = (contact = null) => {
    if (contact) {
      setContactForm({
        id: contact.id,
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        photo: contact.photo,
        status: contact.status,
        contactType: Array.isArray(contact.contactType)
          ? contact.contactType
          : ["regular"], // Ensure contactType is an array
      });
    } else {
      setContactForm({
        id: null,
        name: "",
        email: "",
        phone: "",
        photo: "",
        status: "active",
        contactType: ["regular"],
      });
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setContactForm({
      id: null,
      name: "",
      email: "",
      phone: "",
      photo: "",
      status: "active",
      contactType: ["regular"],
    });
  };

  return (
    <Layout clients={clients}>
      {selectedClient && (
        <div className="contact-content">
          <div className="tabs">
            <button
              className={`tab ${selectedTab === "regular" ? "active" : ""}`}
              onClick={() => setSelectedTab("regular")}
            >
              Regular Contacts
            </button>
            <button
              className={`tab ${selectedTab === "emergency" ? "active" : ""}`}
              onClick={() => setSelectedTab("emergency")}
            >
              Emergency Contacts
            </button>
            <button className="add-contact" onClick={() => openModal()}>
              Add Contact
            </button>
          </div>
          <div>
            {selectedTab === "regular" &&
              (contacts[selectedClient]?.regular?.length > 0 ? (
                <ul>
                  {contacts[selectedClient]?.regular
                    ?.slice()
                    .reverse()
                    .map((contact) => (
                      <li key={contact.id} className="contact-item">
                        <div className="contact-name">{contact.name}</div>
                        <div className="contact-email">{contact.email}</div>
                        <div className="contact-phone">{contact.phone}</div>
                        <button onClick={() => openModal(contact)}>Edit</button>
                      </li>
                    ))}
                </ul>
              ) : (
                <p>No regular contacts available</p>
              ))}
            {selectedTab === "emergency" &&
              (contacts[selectedClient]?.emergency?.length > 0 ? (
                <ul>
                  {contacts[selectedClient]?.emergency
                    ?.slice()
                    .reverse()
                    .map((contact) => (
                      <li key={contact.id} className="contact-item">
                        <div className="contact-name">{contact.name}</div>
                        <div className="contact-email">{contact.email}</div>
                        <div className="contact-phone">{contact.phone}</div>
                        <button onClick={() => openModal(contact)}>Edit</button>
                      </li>
                    ))}
                </ul>
              ) : (
                <p>No emergency contacts available</p>
              ))}
          </div>
        </div>
      )}
      {isModalOpen && (
        <>
          <div className="contact-modal-overlay" onClick={closeModal}></div>
          <div className="contact-modal">
            <div className="contact-modal-header">
              <h2>Add Contact</h2>
              <button
                className="contact-modal-close-button"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
            <div className="contact-modal-body">
              <input
                type="text"
                name="name"
                value={contactForm.name}
                placeholder="Name"
                onChange={handleInputChange}
              />
              <input
                type="email"
                name="email"
                value={contactForm.email}
                placeholder="Email"
                onChange={handleInputChange}
              />
              <input
                type="tel"
                name="phone"
                value={contactForm.phone}
                placeholder="Phone"
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="photo"
                value={contactForm.photo}
                placeholder="Photo URL"
                onChange={handleInputChange}
              />

              <select
                name="status"
                value={contactForm.status}
                onChange={handleInputChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              <div className="contact-type-options">
                <label className={contactForm.contactType.includes("regular") ? "selected" : ""}>
                  <input
                    type="checkbox"
                    name="contactType"
                    value="regular"
                    checked={contactForm.contactType.includes("regular")}
                    onChange={handleInputChange}
                  />
                  Regular
                </label>
                <label className={contactForm.contactType.includes("emergency") ? "selected" : ""}>
                  <input
                    type="checkbox"
                    name="contactType"
                    value="emergency"
                    checked={contactForm.contactType.includes("emergency")}
                    onChange={handleInputChange}
                  />
                  Emergency
                </label>
              </div>
            </div>
            <div className="contact-modal-footer">
              <button onClick={handleSaveContact}>Save</button>
              <button onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default withAuthenticationRequired(ContactsPage);

