import React, { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";

const BASE_URL = process.env.REACT_APP_BASE_URL;

function TermsPage() {
  const { user, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      if (localStorage.getItem("userIsTermsAccepted") == "true") {
        setIsLoaded(false);
        window.location.href = "/select-client-homepage";
      } else {
        setIsLoaded(true);
      }
    }, 2000);
  }, [isLoaded]);

  async function handleSubmit(event) {
    event.preventDefault();

    const response = await fetch(`${BASE_URL}/users`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        uid: user.sub,
        email: user.email,
        is_terms_accepted: true,
      }),
    });

    if (response.ok) {
      localStorage.setItem("userIsTermsAccepted", true);
      window.location.href = "/select-client-homepage";
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTermsAccepted(event.target.checked);
  };

  return !isLoaded ? (<div className="App-content"></div>) : (
    <div className="App-content" style={{ padding: "48px 144px" }}>
      <div>
        <p style={{ fontSize: "36px" }}>
          <strong>GRAY MATTERS ALLIANCE, LLC</strong>
        </p>
        <p style={{ fontSize: "30px" }}>
          <strong>USER AGREEMENT</strong>
        </p>

        <p style={{ fontSize: "18px", textAlign: 'left' }}>
          Last updated: April 1<sup>st</sup> 2024
        </p>
        <p>
          <strong>
            THIS CONTRACT CONTAINS IMPORTANT INFORMATION REGARDING YOUR RIGHTS
            AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND EXCLUSIONS
            THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY. THIS CONTRACT
            REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
            DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.&nbsp;
          </strong>
        </p>
        <p>
          You agree that by signing this document,&nbsp;&nbsp;&ldquo;I
          Agree,&rdquo; &ldquo;Sign Up,&rdquo; or registering, accessing or
          using our services (described below),{" "}
          <strong>
            you are agreeing to enter into legally binding contract&nbsp;
          </strong>
          with Gray Matters Alliance, LLC (&ldquo;<strong>GMA</strong>&rdquo;).
          If you do not agree to this contract (&ldquo;
          <strong>Contract&rdquo;&nbsp;</strong>or &ldquo;
          <strong>User Agreement</strong>&rdquo;), do not click &ldquo;I
          Agree&rdquo; (or similar) and do not access or otherwise use any of
          our Services (as defined below). If you wish to terminate this
          contract, at any time you can do so by closing your Account (as
          defined below) and no longer accessing or using our Services.&nbsp;
        </p>
        <p>
          1. <strong>Services.&nbsp;</strong>This User Agreement applies to the
          GMA platform systems, currently known as &ldquo;GMA System II&rdquo;
          or &ldquo;GMA System III or &ldquo;Cortex&rdquo; or &ldquo;GMA
          MyCompass&rdquo; (the &ldquo;
          <strong>Software Services&rdquo;&nbsp;</strong>or the
          <strong>&nbsp;&ldquo;GMA System</strong>&rdquo;).&nbsp;
        </p>
        <p>
          <strong>2.</strong> <strong>GMA.&nbsp;</strong>You are entering into
          this Contract with Gray Matters Alliance, LLC, a Missouri Limited
          Liability Company (also referred to as &ldquo;<strong>we</strong>
          &rdquo; and &ldquo;<strong>us</strong>&rdquo;).
        </p>
        <p>
          <strong>3. Changes.&nbsp;</strong>We may modify this Contract and our
          Privacy Policy from time to time. If we make materials changes to it,
          we will provide you notice through our Services, or by other means, to
          provide you the opportunity to review the changes before they become
          effective. We agree that changes cannot be retroactive. If you object
          to any changes, you may close your Account (as defined below) and stop
          using the Services. Your continued use of our Services after we
          publish or send a notice about our changes to these terms means that
          you consent to the updated terms as of their effective date.&nbsp;
        </p>
        <p>
          <strong>4. Service Eligibility.&nbsp;</strong>The Services are not for
          use by anyone under the Minimum Age. To use the Services, you agree
          that you must be the Minimum Age or older. Creating an Account with
          false information is a violation of our terms, including accounts
          registered on behalf of other persons under the Minimum
          Age.&nbsp;&nbsp;&ldquo;<strong>Minimum Age&rdquo;&nbsp;</strong>means
          13 years old; however, if law requires that you must be older in order
          for GMA to lawfully provide the Services to you without parental
          consent (including using of your personal data) then the Minimum Age
          is such older age.&nbsp;
        </p>
        <p>
          <strong>5. Your Account.&nbsp;</strong>You may not disclose your
          password or username to any third party. You shall promptly notify us
          if you believe your Account, password, or username has been
          compromised. You are responsible for anything that happens through
          your Account unless you close it or report misuse. As between you and
          others, your Account belongs to you. However, if the Services were
          purchased by another party for us by a Senior or person/s with
          disabilities also known as the <strong>End User</strong> (e.g., by the
          Facility), the party paying for such services may terminate that
          Senior&rsquo;s Account and that Senior&rsquo;s and/or End User&rsquo;s
          access to the Services may terminate that Senior&rsquo;s Account and
          that Senior&rsquo;s access to the Services may terminate that Seniors
          or End Users Account and that Senior&rsquo;s or End User&rsquo;s
          access to the Services to the extent paid for by such party. We can
          disable your Account immediately for so long as necessary to protect
          our Services or for as long as you are violating this Contract. If we
          take action to disable your account, we will promptly notify you. If
          you believe your Account, we will promptly notify you. If you believe
          your Account has been disabled in error, or if you want to terminate
          or permanently delete your Account, please contact us at:
          itsupport@graymattersalliance.com or info@graymattersalliance.com.
          &nbsp;
        </p>
        <p>
          <strong>6. Use; Restrictions.&nbsp;</strong>You may use the Services
          only during the Term and in accordance with this Contract and the
          Documentation. You agree not to, and shall not permit ay third party
          to: (a) sublicense, redistribute, sell, lease, lend or rent the
          Services; (b) make the Services available over a network where it
          could be used by multiple devices owned or operated by different
          people at the same time; (c) disassemble, reverse engineer, decompile,
          decrypt, or attempt to derive the source code of, the Services; (d)
          copy, modify, improve, or create derivative works of the Services; (e)
          circumvent, disable or otherwise interfere with security-related
          features of the Services or features that prevent or restrict use or
          copying of any content or that otherwise limit use of the Services;
          (f) use any communications systems provided by the Services to send
          unauthorized and/or unsolicited commercial communications; (g) try to
          gain unauthorized access to, test the vulnerability of, or disrupt the
          Services; (h) distribute spam or malware, or (i) use the Services in
          any way that violates the privacy of others, any applicable laws,
          rules or regulations, for any unlawful, harmful, irresponsible, or
          inappropriate purpose, or in any manner that breaches this
          Contract.&nbsp;
        </p>
        <p>
          <strong>7. Incidental/Third Party Software.&nbsp;</strong>You may
          receive software from us provided by a third party that is incidental
          to your use of the Services which is installed on a tablet or other
          cellular device. You may only use that software in connection with its
          use of the Services and in accordance with this
          Contract.&nbsp;&nbsp;The incidental software installed and used by our
          software platform is subject to the terms, conditions and privacy
          policy of any such third party software.
        </p>
        <p>
          <strong>8. Network Limitations.&nbsp;</strong>Your access and the use
          of the Application will depend on the capabilities of Your
          mobile/tablet device and the mobile/internet carrier You have
          contracted with for mobile service. GMA shall in no way be deemed
          responsible for a failure in performance of the Application due to
          network outages, failures or other interruptions of service, including
          attempts to use the Application while in an area that receives
          sporadic, limited or no coverage or the failure of Internet access
          equipment or associated networks and/or unprotected power
          outages.&nbsp;
        </p>
        <p>
          <strong>9. Monitoring.&nbsp;</strong>We may monitor and collect
          configuration, performance, and usage data relating to your use of the
          Services: (a) to facilitate delivery of the Services (such as (i)
          tracking entitlements, (ii) providing support, (iii) monitoring the
          performance, integrity, and stability of the Services infrastructure,
          and (iv) preventing or addressing service or technical issues); and
          (b) to improve our products and services, and your experience
          (collectively, &ldquo;<strong>Usage Data</strong>&rdquo;). You must
          not interfere with that monitoring. We will not access Your
          Submissions or your MyHealth Data except as necessary to provide the
          Services or pursuant to Section 10(c).&nbsp;
        </p>
        <p>
          <strong>10. Your Submissions.&nbsp;</strong>
        </p>
        <p>
          <strong>a.&nbsp;</strong>&nbsp; &nbsp;{" "}
          <strong>Communication and Sharing of Content.&nbsp;</strong>The
          Services allow you to communicate and share Content with Users
          (&ldquo;<strong>Your Submissions</strong>&rdquo;) and for you to view
          and receive the Content of other Users (&ldquo;
          <strong>User Submissions</strong>&rdquo;). Where we have made settings
          available, we will honor the choices you make about who can see Your
          Submissions and, for Seniors, who is in your MyGuides. You acknowledge
          and agree that (i) via the Services, Your Content is being shared
          directly with the Facility and the MyGuides and (ii) we do not
          guarantee any confidentiality, and you should have no expectation of
          privacy, with respect to any of Your Submissions and we disclaim any
          and all liability arising from or in any way related to the use of
          Your Submissions by the Facility, the Senior (if you are not the
          Senior or the End User), or anyone in the MyGuides&nbsp;
        </p>
        <p>
          &nbsp; &nbsp;{" "}
          <strong>b. &nbsp; &nbsp; Ownership of Your Submissions.&nbsp;</strong>
          You represent and warrant that you own or have the necessary rights
          and permissions to use and authorize us to use Your Submissions. You
          retain all of your Intellectual Property Rights in and to Your
          Submissions. Subject to this Contract and the terms of our Privacy
          Policy, by sharing your Content on or with the Services, you hereby
          grant us a worldwide, irrevocable, non-exclusive, royalty-free,
          perpetual, sub-licensable and transferable license to use, reproduce,
          distribute, prepare derivative works of, display, and perform such
          Content only in connection with the Services.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; <strong>c</strong>. &nbsp; &nbsp;{" "}
          <strong>Rights to Your Submissions.&nbsp;</strong>Notwithstanding
          anything to the contrary contained in this Contract or our Privacy
          Policy, we reserve the right, to extent applicable, to access, read,
          preserve, and disclose any Content or Wellness Data or any information
          that we obtain in connection with the Services we reasonably believe
          is necessary to (i) satisfy any applicable law, regulation, legal
          process, subpoena or governmental request, (ii) enforce this Contract,
          including investigation of potential violations of it, (iii) detect,
          prevent, or otherwise address fraud, security or technical issues, or
          (iv) respond to support requests.&nbsp;
        </p>
        <p>
          <strong>11.</strong>&nbsp; &nbsp;{" "}
          <strong>Acknowledgement of Seniors and MyGuides.&nbsp;</strong>
        </p>
        <p>
          <strong>
            &nbsp; &nbsp; a. &nbsp; &nbsp; Sound Mind and Body.&nbsp;
          </strong>
          If you are a Senior, you confirm that you are of sound mind and body
          and are able to understand and agree to this Contract. If you are a
          member of MyGuide, you confirm that the Senior of whose you are will
          be a member appears to be of sound mind and body and is able to
          understand and agree to this Contract. You acknowledge and agree that
          we are relying on your confirmation.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; <strong>b. &nbsp; &nbsp; Health Data.&nbsp;</strong>The
          Platform and Third Party Services connected with and into the Platform
          Process certain types of Health Data, and if you are a Senior, by
          using such Third Party Services, you acknowledge and agree that (i)
          you are directly providing or granting access to that Health Data to
          the Facility and the members of your MyGuides that you designate and
          (ii) you have no expectation of privacy with respect to that Health
          Data. If you do not want your Health Data Processed, do not use or
          agree to use Third Party Services that track, monitor, or collect
          Health Data.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp;{" "}
          <strong>c. &nbsp; &nbsp; End User Submissions and Data.&nbsp;</strong>
          Except as permitted by a User, you shall not Process such User&rsquo;s
          User Submissions outside of or separate from the Service&rsquo;s
          platform and user interfaces. You acknowledge and agree that such User
          is a Third-Party beneficiary of this Section 11(c).&nbsp;
        </p>
        <p>
          <strong>d.&nbsp;</strong>&nbsp; &nbsp;{" "}
          <strong>MyGuide Designations.&nbsp;</strong>If you are a MyGuide
          member, you acknowledge and agree that your right to use the Services
          in relation to a particular Senior or developmentally disabled
          individual derives from your continued designation by that Senior to
          be a part of his or her MyGuides. Seniors have the right, for any
          reason or no reason, to modify or change the members of their
          MyGuides, and we will not be responsible or liable for any
          designations, modifications, or changes to the members of a MyGuide
          made by a Senior.&nbsp;
        </p>
        <p>
          12. &nbsp; &nbsp;
          <strong>
            Not a Replacement for Personal Care. &nbsp; &nbsp;&nbsp;
          </strong>
          The GMA System is a tool meant to enhance relationships and not to
          replace the personal touch given by extended family or
          caregivers.&nbsp;
        </p>
        <p>
          13. &nbsp; &nbsp;
          <strong>Not a Personal Emergency Response System (PERS)&nbsp;</strong>
          may not be used for crisis management and is not a substitute for the
          on-line and physical monitoring of anyone with serious health
          conditions or disabilities.&nbsp;
        </p>
        <p>
          14. &nbsp; &nbsp;
          <strong>
            The GMA System does not diagnose or treat health conditions.&nbsp;
          </strong>
          Health and wellness should be monitored by licensed medical
          professionals.&nbsp;
        </p>
        <p>
          15. &nbsp; &nbsp;<strong>Third Party Services.&nbsp;</strong>The
          Services may enable you to view, access, link to, and use content and
          services provided by third parties (&ldquo;
          <strong>Service Providers</strong>&rdquo;) that are not owned or
          controlled by us, including, without limitation, wearables, medical
          vital sensors such as blood pressure, glucose, seizure, PERS (personal
          emergency response), motion sensors, music players, photo albums and
          photosharing technologies, text messages services, voice and video
          file services, etc. (&ldquo;<strong>Third Party Services</strong>
          &rdquo;), which are governed by their own separate terms and
          conditions. You are solely responsible and liable for your interaction
          with a Service Provider. You agree to waive, hereby do waive, any
          legal or equitable right or remedies you may have against us and
          release us from any and all liability arising from your use of and
          interaction with any Third Party Services.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; <strong>a. Links.</strong> The Application may provide
          links to websites, services or resources. When you access third party
          websites, services or resources, You do so at your own risk. You
          acknowledge and agree that GMA is not responsible for the availability
          of such external sites or resources and does not endorse and is not
          responsible or liable for any advertising, products or other materials
          on or available from such sites or resources. You further acknowledge
          and agree that GMA shall not be responsible or liable, directly or
          indirectly, for any damage or loss caused by alleged to be caused by
          or in connection with use of or reliance on any such goods or services
          available on or through any such site or resource.
        </p>

        <p>
          <br />
        </p>
        <p>
          <strong>16. Warranties; Disclaimers.&nbsp;</strong>To the maximum
          extent permitted by applicable law, you and we agree as follows:&nbsp;
        </p>
        <p>
          <strong>a. &nbsp; &nbsp;</strong>THE SERVICES ARE PROVIDED FOR YOUR
          CONVENIENCE, &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo;, AND
          WE, AND OUR LICENSORS AND SUPPLIERS, EXPRESSLY DISCLAIM ANY WARRANTIES
          AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE
          WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, ACCURACY AND NON-INFRINGEMENT.&nbsp;
        </p>
        <p>
          <strong>b. &nbsp; &nbsp;</strong>WE, AND OUR LICENSORS AND SUPPLIERS,
          MAKE NO WARRANTY THATR DEFECTS WILL BE CORRECTED OR THAT THE SERVICES:
          (i) WILL MEET YOUR REQUIREMENTS: (ii) WILL BE COMPATIBLE WITH YOUR
          NETWORK, COMPUTER, TABLET MOBILE DEVICE, OR OTHER DEVICE;
          (iii)&nbsp;&nbsp;WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE
          OR ERROR-FREE BASIS; OR (iv) WILL BE ACCURATE OR RELIABLE. NO ADVICE
          OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR
          THROUGH THE SERVICES WILL CREATE ANY WARRANTY.&nbsp;
        </p>
        <p>
          <strong>c. &nbsp; &nbsp;</strong>WE DO NOT WARRANT, ENDORSE, GUARANTEE
          OR ASSUME RESPONSIBITY FOR ANY SERVICE ADVERTISED OR OFFERED BY A
          THIRD PARTY THROUGH, OR IN CONNECTION WITH, THE SERVICES, OR ANY
          HYPERLINKED WEBSITE OR SERVICE, AND WE WILL NOT BE A PARTY TO ANY
          TRANSACTION BETWEEN YOU AND A THIRD-PARTY PROVIDER OF SUCH PRODUCTS OR
          SERVICES.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; <strong>d. &nbsp; &nbsp;</strong>WE MAKE NO
          REPRESENTATIONS CONCERNING ANY THIRD-PARTY CONTENT OR SERVICES
          CONTAINED IN OR ACCESSED THROUGH THE SERVICES, AND WE WILL NOT BE
          RESPONSIBLE OR LIABLE FOR THE ACCURACY, COPYRIGHT COMPLIANCE, LEGALITY
          OR DECENCY OF MATERIAL CONTAINED IN OR ACCESSED THROUGH THE SERVICES.
          WE MAKE NO REPRESENTATIONS OR WARRANTIES REGARDING SUGGESTIONS OR
          RECOMMENDATIONS OF SERVICES OFFERED OR PURCHASED THROUGH THE
          SERVICES.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; <strong>e. &nbsp; &nbsp;&nbsp;</strong>THE SERVICES ARE
          NOT TO BE RELIED UPON (i) FOR ANY LIFE SAFETY OR CRITICAL PURPOSES,
          FOR AVOIDING, DETECTING, OR COMBATTING AGAINST THE FRAUDULENT OR
          PREDATORY ACTS OF OTHERS, AND (ii) THE SERVICES ARE NOT A MEDICAL
          DEVICE OR INTENDED TO PREPARE OR PROVIDE MEDICAL DIAGNOSIS. ANY
          INFORNMATION AND REPORTS GENERATED BY US SHOULD NOT BE INTERPRETED AS
          A SUBSTITUTE FOR PHYSICIAN CONSULTATION, EVALUATION, TREATMENT, OR
          GOOD SENSE AND PERSONAL JUDGEMENT ABOUT ONE&rsquo;S OWN CONDITION. IF
          YOU BELIEVE THAT THERE IS A MEDICAL EMERGENCY, DIAL 911 WITHOUT
          DELAY.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; <strong>f. &nbsp; &nbsp;</strong>WE MAKE NO ENDORSEMENT,
          REPRESENTATION, OR WARRANTY OF ANY KIND ABOUT ANY HEALTH DATA. THE
          ACCURACY OF THE WELLNESS DATA IS NOT INTENDED TO MATCH THAT OF MEDICAL
          DEVICES OR SCIENTIFIC MEASURMENT DEVICES. WE ARE NOT RESPONSIBLE FOR
          THE ACCURACY, RELIABILITY, AVAILABILITY, EFFETIVENESS, OR CORRECT USE
          OF WELLNESS DATA YOU OR THE FACILITY RECEIVES THROUGH THE SERVICES.
          WELLNESS DATA MAY BE UNAVAILABLE, INACCURATE, OR INCOMPLETE.&nbsp;
        </p>
        <p>
          <strong>
            17. &nbsp; &nbsp; Intellectual Property Rights; Feedback.&nbsp;
          </strong>
          We and our suppliers own and retain all right, title, and interest in
          and to the services and any related GMA software, including all
          improvements, enhancements, modifications, and derivative works of
          them, and all Intellectual Property Rights in all of them. This
          includes any Usage Data. Your rights to use the Services are limited
          to those expressly granted in this Contract. No other rights with
          respect to the Services, any related GMA software, or any related
          Intellectual Property Rights are implied. If you provide us any
          Feedback on the Services, then we may use that information without
          obligation to you, and you hereby irrevocably assign to GMA all right,
          title, and interest in and to such Feedback.&nbsp;
        </p>
        <p>
          18. &nbsp; &nbsp;
          <strong>Privacy; Protected Health Information.&nbsp;&nbsp;</strong>We
          respect your privacy and are committed to protecting it. Our Privacy
          Policy governs the Processing of all personal data collected from you
          by us in connection with your use of the Services. [Notwithstanding
          anything to the contrary, if any of Your Submissions or our Health
          Data is Protected Health Information, such data will be Processed in
          accordance with our Privacy Policy and HIPAA.&nbsp;&nbsp;GMA is not an
          entity that incorporates tracking technologies in our software as that
          term is defined by the Department of Health and Human Services and
          regulated by HIPAA.&nbsp;&nbsp;Additionally, GMA is not a business
          associate that creates, receives, maintains, transmits or captures
          health information protected by HIPAA.
        </p>
        <p>
          19. &nbsp; &nbsp; <strong>Indemnification.&nbsp;</strong>You agree to
          indemnify and hold harmless GMA and our affiliates, and our respective
          officers, directors, employees and agents, from and against any and
          all claims, damages, obligations, losses, liabilities, cost and
          expenses (including but not limited to attorney&rsquo;s fees) arising
          from: (a) your use of, or inability to use, the Services; (b) your
          violation pf this Contract or applicable laws, regulations or
          obligations; and (c) your violation of any third party right,
          including without limitation any copyright, property, or privacy
          right.&nbsp;
        </p>
        <p>
          20. &nbsp; &nbsp; <strong>Limitations or Liability.&nbsp;</strong>TO
          THE FULLEST EXTENT PERMITTED BY LAW, GMA, INCLUDING ITS AFFILIATES,
          (a) WILL NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR LOST
          PROFITS OR LOST BUISNESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR
          DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF,
          OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY INDIRECT,
          INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES AND (b) WILL
          NOT BE LIABLE TO YOU IN CONNECTION WITH THIS CONTRACT FOR ANY AMOUNT
          THAT EXCEEDS THE GREATER OF (i) THE TOTAL FEES PAID OR PAYABLE BY YOU
          TO GMA FOR THE SERVICES DURING THE TERM OF THIS CONTRACT, IF ANY, AND
          (ii) US $1,000.&nbsp;
        </p>
        <p>
          <strong>21. &nbsp; &nbsp;TERMINATION.&nbsp;</strong>
        </p>
        <p>
          <strong>&nbsp; &nbsp;&nbsp;</strong>a. &nbsp; &nbsp;
          <strong>Your Right to Terminate.&nbsp;</strong>You may stop using the
          Services at any time. You may terminate this Contract immediately upon
          written notice to us, provided that if you are a Senior, you or the
          Facility will remain liable for any and all fees and charges otherwise
          due during your Subscription.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          &nbsp; &nbsp; b. &nbsp; &nbsp; <strong>MyCompass.&nbsp;</strong>If you
          are a MyCompass member, this Contract will automatically terminate if
          you are removed as a member from all MyCompass and Cortex (and you are
          not also a Senior with a valid and in effect Subscription).&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; c. &nbsp; &nbsp; <strong>Seniors.&nbsp;</strong>If you
          are a Senior, this contract will automatically terminate upon your
          death, the termination or expiration of your Subscriptions, or if the
          Facility reallocates your Subscription.
        </p>
        <p>
          &nbsp; &nbsp; d. &nbsp; &nbsp;
          <strong>Termination for Cause.&nbsp;</strong>Either you or we may
          terminate this Contract effective immediately upon written notice to
          the other party if that party (i) commits a breach of this Contract
          and fails to cure within 30 days of notice of the breach or (ii)
          commits a material breach of this Contract that cannot be cured.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; e. &nbsp; &nbsp;
          <strong>Effect of Termination.&nbsp;</strong>Upon termination of this
          Contract for any reason: (i) you must stop using the Services, (ii)
          deletion of any of Your Submissions or Health Data remaining in the
          Services will occur as specified in the Privacy Policy, and (iii)
          Sections 1, 2, 4, 10(b),10(c), 11(a), 12 through 20 will
          survive.&nbsp;
        </p>
        <p>
          <strong>22. &nbsp; &nbsp;General.</strong>
        </p>
        <p>
          <strong>&nbsp; &nbsp;&nbsp;</strong>a. &nbsp; &nbsp;
          <strong>Subcontracting.&nbsp;</strong>We may subcontract any of our
          obligations under this Contract, including the provision of the
          Services without notice to you. We shall remain responsible to you for
          the performance of our obligations hereunder that are performed by the
          subcontractor.&nbsp;
        </p>
        <p>
          <strong>&nbsp; &nbsp;&nbsp;</strong>b. &nbsp; &nbsp;{" "}
          <strong>Export Compliance.</strong> You shall not directly or
          indirectly, export (including and &ldquo;deemed export&rdquo;), nor
          re-export (including any &ldquo;deemed re-export&rdquo;) the
          Intellectual Property Rights (including any associated products,
          items, articles, computer software, media, Services, technical data,
          and other information) in violation of any applicable laws.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; c. &nbsp; &nbsp; <strong>Governing Law.&nbsp;</strong>
          The laws of the State of Missouri, excluding conflicts of laws rules,
          will exclusively govern any dispute relating to this Contract or the
          Services. The U.N. Convention on Contracts for the International Sale
          of Goods does not apply.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; d.{" "}
          <strong>Dispute Resolution; Binding Arbitration.&nbsp;</strong>
        </p>
        <p>
          <strong>&nbsp; &nbsp; &nbsp; &nbsp; (i) &nbsp; &nbsp;</strong>YOU AND
          US ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR
          BEFORE A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE
          ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF
          YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN
          ARBITRATION. ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT,
          TORT, OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR FUTURE, AND
          INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL
          TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN YOU AND US ARE ARISING
          FROM OR RELATING IN ANY WAY TO THE SERVICES OR THIS CONTRACT, WILL BE
          RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; <strong>(ii) &nbsp; &nbsp;</strong>The arbitration will
          be administered by the American Arbitration Association (&ldquo;
          <strong>AAA</strong>&rdquo;) in accordance with the Consumer
          Arbitration Rules (the &ldquo;<strong>AAA Rules</strong>&rdquo;) then
          in effect, except as modified by this Section 18(d). The Federal
          Arbitrator Act will govern the interpretation and enforcement of this
          Section 18(d). The arbitrator will have exclusive authority to resolve
          any dispute relating to arbitrability and/or enforceability of this
          arbitration provision, including any unconscionable challenge or any
          other challenge that the arbitration provision or this Contract is
          void, voidable or otherwise invalid. The arbitrator will be empowered
          to grant whatever relief would be available in court under law or in
          equity. Any award of the arbitrator(s) will be final and binding on
          each of the parties and may be entered as a judgment in any court of
          competent jurisdiction. The parties agree that any required in-person
          arbitration hearings will occur in or around St. Louis, Missouri, or
          as otherwise determined by the arbitrator.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; <strong>(iii) &nbsp; &nbsp;</strong>You may elect to
          pursue your claim in small-claims court rather than arbitration if you
          provide us with written notice of your intention do so within 60 days
          of first clicking &ldquo;I Agree,&rdquo; or similar to this Contract.
          The arbitration or small-claims court proceeding will be limited
          solely to your individual dispute or controversy.&nbsp;
        </p>
        <p>
          <strong>&nbsp; &nbsp; (iv) &nbsp; &nbsp;</strong>You agree to
          arbitration on an individual basis. In any dispute, NEITHER YOU NOR WE
          WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER
          USERS OF THE SERVICES IN COURT OR IN ARBITRATION OR OTHERWISE
          PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN
          A PRIVATE ATTORNEY GENERAL CAPACITY. The arbitral tribunal may not
          consolidate more than one person&rsquo;s claims, any may not otherwise
          preside over any form of a representative or class proceeding. The
          arbitral tribunal has no power to consider the enforceability of this
          class arbitration waiver and any challenge to the class arbitration
          waiver may only be raised in a court of competent jurisdiction.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; <strong>(v) &nbsp; &nbsp;</strong>If any provision of
          this arbitration agreement is found unenforceable, the unenforceable
          provision will be served and the remaining arbitration terms will be
          enforced.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; e. &nbsp; &nbsp; <strong>Recovery of Expenses.</strong>{" "}
          In any proceeding between the parties arising out of this Contract or
          the transactions it contemplates, the prevailing party will be
          entitled to recover from the other party, in addition to any other
          relief awarded, all expenses that the prevailing party incurs,
          including reasonable legal fees and expenses.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; f. &nbsp; &nbsp; <strong>Notices.</strong>&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp;{" "}
          <strong>(i)&nbsp;&nbsp;To You.&nbsp;</strong>We may provide any notice
          to you under this Contract by: (1) sending a message to the email
          address associated with your Account or (2) by posting to the Services
          portal. Notices sent by email will be effective when we send the email
          and notices we provide by posting will be effective upon posting. It
          is your responsibility to keep your email address current.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp;{" "}
          <strong>(ii) &nbsp; &nbsp;To Us.&nbsp;</strong>To give us notice under
          this Contract, you must direct legal notices or other correspondence
          to Farrell &amp; Martin, 220 Salt Lick Road, St. Peters, Missouri
          63376, Attention: B. Martin. We may update our address for notices by
          posting a notice on our website.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; g. &nbsp; &nbsp;<strong>Force Majeure.</strong> Except
          for your payment obligations, if any, neither party will be liable for
          any delay or failure to perform their obligations under this Contract
          due to any cause beyond such party&rsquo;s reasonable control
          including labor disputes or other industrial disturbances, systemic
          electrical, telecommunications or other utility failures, earthquakes,
          storms or other acts of nature, embargoes, riots, acts or orders of
          government, acts of terrorism, or war.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; h. &nbsp; &nbsp;{" "}
          <strong>Assignment; Delegation.&nbsp;</strong>You will not assign any
          of your rights or delegate any of your obligations under this Contract
          without our prior written consent. Any purported assignment or
          delegation in violation of this Section 22(h) is null and void. No
          assignment or delegation relieves you of any of your obligations under
          this Contract.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; i. &nbsp; &nbsp;
          <strong>Third Party Rights.&nbsp;</strong>Except as otherwise set
          forth in Section 11(c) and 15, this Contract is the sole benefit of
          the parties hereto and their respective successors and permitted
          assigns and nothing herein, express or implied, is intended to or
          confers upon any other person or entity any legal or equitable right,
          benefit, or remedy of any nature whatsoever, under or by reason of
          Contract.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; j. &nbsp; &nbsp; <strong>Entire Agreement</strong>. This
          Contract, along with the Privacy Policy and the PHI Addendum,
          constitutes the entire agreement between the parties with respect to
          the subject matter hereof and thereof and supersedes all other
          agreements, whether written or oral, between the parties.&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; k. &nbsp; &nbsp;<strong>Severability.&nbsp;</strong>If
          any provision of this Contract is held to be invalid or unenforceable,
          the remaining provisions of this Contract will remain in force to the
          extent feasible.&nbsp;
        </p>
        <p>
          23. &nbsp; &nbsp; <strong>Definitions.</strong>&nbsp;
        </p>
        <p>
          &ldquo;<strong>Account</strong>&rdquo; means to GMA or the GMA System
          account, either as a senior, a MyGuide member of another Senior, or
          both.&nbsp;
        </p>
        <p>
          &ldquo;<strong>MyGuides</strong>&rdquo; means designees of End Users
          and Seniors who may communicate with such End User and/or Senior via
          the Services and may have access to the Senior&rsquo;s and/or End
          User&rsquo;s Content and MyHealth Data that the Senior
          designates.&nbsp;
        </p>
        <p>
          &ldquo;<strong>Content</strong>&rdquo; means any data, including,
          without limitation, text, sound, video, or image files, and software
          (including machine images), or other information.&nbsp;
        </p>
        <p>
          &ldquo;<strong>Documentation</strong>&rdquo; means the description of
          the Services which can be found at www.gmacortex.app/documentation.
        </p>
        <p>
          &ldquo;<strong>End User&rdquo;&nbsp;</strong>means the individual
          client that is the main user being provided supportive and assistive
          technology in which GMA is providing services for. &nbsp;
        </p>
        <p>
          <strong>&ldquo;Facility&rdquo;&nbsp;</strong>means the end
          user&rsquo;s living facility, independent living agency, assisted
          living facility, or any other organization that has purchased the End
          User&rsquo;s Subscription.
        </p>
        <p>
          <strong>&ldquo;Feedback&rdquo;</strong> means feedback or suggestions
          about the Services provided to GMA by you.&nbsp;
        </p>
        <p>
          <strong>&ldquo;Intellectual Property Rights&rdquo;&nbsp;</strong>means
          all patent rights, copyright, trademark rights, rights in trade
          secrets, database rights, moral rights, and any other intellectual
          property rights (registered or unregistered) throughout the
          world.&nbsp;
        </p>
        <p>
          <strong>&ldquo;Process&rdquo;</strong> means any operation or set of
          operations performed upon data, such as accessing, obtaining, storing,
          transmitting, using, maintaining, disclosing or disposing of the
          information.
        </p>
        <p>
          <strong>&ldquo;Senior&rdquo;&nbsp;</strong>means the elderly person
          who is the Facility&rsquo;s designee.&nbsp;
        </p>
        <p>
          <strong>&ldquo;Subscription&rdquo;</strong> means a monthly
          entitlement of a Senior to use the Services pursuant to this Contract
          which is paid for by the Facility.&nbsp;
        </p>
        <p>
          <strong>&ldquo;Term&rdquo;&nbsp;</strong>means the term of this
          Contract beginning on date of your acceptance of this Contract and
          ending on the termination of this Contract in accordance with section
          17.&nbsp;
        </p>
        <p>
          <strong>&ldquo;Users&rdquo;&nbsp;</strong>means Seniors, End Users,
          Person/s with Disabilities Facilities, and MyGuide members.&nbsp;
        </p>
        <p>
          <strong>&ldquo;Health Data&rdquo;&nbsp;</strong>means certain end user
          datat including such Seniors (i) use of the Services, (ii) body
          temperature, blood pressure, heart rate, glucose level, respiratory
          rate, (iii) activity level, (iv) nutrition or hydration levels, (v)
          sleep routines (vi), the Application consists of a precautionary
          supplemental personal safety tool, (vii) responses to prompts and
          questionnaires soliciting mental health and personal health data, and
          (viii) pill and medication reminders/dispensers.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Installation of Services</strong>
        </p>
        <ul>
          <li>
            <strong>Scope of Installation Services</strong>: Gray Matters
            Alliance LLC (&quot;GMA&quot;) shall provide comprehensive
            installation services of the GMA System (&quot;the Services&quot;)
            through a certified installer. These services include the setup,
            configuration, and testing of the equipment to ensure its proper
            functioning and integration into the user&apos;s environment.
          </li>
          <li>
            <strong>Responsibility for Installation Approval</strong>: It is the
            sole responsibility of the End User (&quot;you&quot;) to secure any
            necessary permissions for the installation of GMA&apos;s equipment.
            This is especially pertinent in rental properties, where you must
            obtain explicit consent from property management or landlords prior
            to the installation process.
          </li>
          <li>
            <strong>Installation Site Requirements</strong>: You are responsible
            for ensuring that the installation site is ready and suitable for
            the installation of the Services. This includes, but is not limited
            to, providing a safe and accessible area for the installer to work,
            ensuring all necessary electrical and network connections are
            available, and that the site complies with any specific requirements
            set forth by GMA.
          </li>
          <li>
            <strong>Cooperation with Installers</strong>: You agree to provide
            full cooperation to GMA&apos;s installers during the installation
            process. This includes providing access to the installation site,
            answering any queries regarding the installation, and adhering to
            any safety or operational guidelines provided by the installer.
            <strong>&nbsp;</strong>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <strong>End User Responsibilities</strong>
        </p>
        <ul>
          <li>
            <strong>Securing Necessary Approvals</strong>: The End User is
            responsible for obtaining all required permissions and consents for
            the installation of the GMA System, particularly in leased or rented
            properties. This includes, but is not limited to, acquiring explicit
            authorization from property managers or landlords.
          </li>
          <li>
            <strong>Preparation of Installation Site</strong>: The End User must
            ensure that the location designated for the GMA System installation
            is adequately prepared. This preparation encompasses providing a
            safe and accessible environment for the installer, ensuring the
            availability of necessary electrical and connectivity resources, and
            complying with any specific prerequisites stipulated by GMA.
          </li>
          <li>
            <strong>Collaboration with Installation Personnel</strong>: The End
            User agrees to fully cooperate with GMA&apos;s installation
            personnel. This cooperation involves granting access to the
            premises, responding promptly and accurately to inquiries related to
            the installation, and adhering to any operational or safety
            instructions issued by the installation team.
          </li>
        </ul>
        <p>
          <br />
          <strong>Technical Support and Equipment Maintenance</strong>
        </p>
        <p>
          a. <strong>Provision of Technical Support</strong>: GMA will provide
          technical support for the GMA System, aimed at maintaining the
          functionality and addressing any technical issues that may arise in
          the course of using our Services.
        </p>
        <p>
          b. <strong>Equipment Maintenance</strong>: In the course of providing
          technical support, should there be a need for equipment maintenance or
          replacement, certain costs may be incurred. These costs are assessed
          on a case-by-case basis, considering the nature of the issue and the
          terms of service coverage.
        </p>
        <p>
          c. <strong>Terms Agreement</strong>: Utilizing GMA&rsquo;s technical
          support services implies your acknowledgment and acceptance of these
          terms, including any potential costs associated with maintaining or
          replacing equipment as part of the service provision.
        </p>
        <p>
          <br />
          <strong>Shipping and Handling with Address Verification</strong>
        </p>
        <ul>
          <li>
            <strong>Due Diligence in Delivery:</strong> GMA will exercise due
            diligence in the handling and shipping of packages to clients,
            ensuring timely and responsible delivery.
          </li>
          <li>
            <strong>Responsibility for Address Verification:</strong> It is the
            collective responsibility of the client, case manager, end user, and
            any appointed guardian to provide a current and safe shipping
            address. Accurate address information is crucial to ensure
            successful delivery.
          </li>
          <li>
            <strong>Tracking Information and Monitoring:</strong> GMA will
            provide tracking details for each shipment, allowing clients to
            monitor their package&rsquo;s journey and anticipate its arrival.
          </li>
          <li>
            <strong>Limitation of Liability for Package Security:</strong>{" "}
            Post-dispatch, GMA cannot be held liable for lost or stolen
            packages. The responsibility for the security of the package upon
            delivery rests with the recipient.
          </li>
        </ul>
        <br />
        <Checkbox onChange={handleCheckboxChange} />
        <span>Agree to the Terms and Conditions</span>
        <div>
          <button
            className="btn-branding"
            style={{ width: "150px" }}
            onClick={handleSubmit}
            disabled={!isTermsAccepted}
          >
            Accept
          </button>
          <span>OR</span>
          <button
            className="btn-branding"
            style={{ width: "150px" }}
            onClick={(ev) => {
              ev.preventDefault();
              logout({
                logoutParams: { returnTo: window.location.origin + "/login" },
              });
              localStorage.clear();
            }}
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(TermsPage);
