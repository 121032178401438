import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Layout from "./Layout";
import { useCaregiver } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";
import "./TrackActivitiesPage.css";
require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;

const TrackActivitiesPage = () => {
  const { selectedClient, setSelectedClient, clientName, setClientName, fetchClients, clients, setClients, fetchClientName, fetchActivities, activities, setActivities } = useContext(ClientContext);
  const { caregiverId, isCaregiver } = useCaregiver();
  const [dismissedActivities, setDismissedActivities] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    console.log("selectedClient", selectedClient);
    
    if (caregiverId) {
      fetchClients();
      fetchClientName(selectedClient)
    }
  }, [caregiverId]);

  useEffect(() => {
    if (selectedClient) {
      fetchClientName(selectedClient).then(() => {
        // console.log('client3name', clientName)
        fetchActivities(selectedClient);
      });
    }
  }, [selectedClient]);
  
  const dismissActivity = (activityId) => {
    setDismissedActivities((prevState) => ({
      ...prevState,
      [selectedClient]: [...(prevState[selectedClient] || []), activityId],
    }));
  };

  return (
    <Layout clients={clients}>
      <div>
        <h1>Track Activities for {clientName}</h1>
        {Object.entries(
          activities.reduce((acc, activity) => {
            const options = { weekday: "long", month: "long", day: "numeric" };
            const activityDate = new Date(activity.start_time);
            const today = new Date();

            // Only include activities that are due today or earlier
            if (activityDate <= today) {
              const date = activityDate.toLocaleDateString(undefined, options);
              if (!acc[date]) acc[date] = [];
              acc[date].push(activity);
            }

            return acc;
          }, {}),
        )
          .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA)) // Sort by date in descending order
          .map(([date, activities]) => (
            <div key={date} className="activity-day">
              <h2 className="activity-date">{date}</h2>
              <ul className="track-activities-list">
                {activities.map((activity) => (
                  <li
                    key={activity.id}
                    className={`track-activities-list-item ${
                      activity.event_state === "completed"
                        ? "completed"
                        : "incomplete"
                    }`}
                  >
                    <div className="activity-details">
                      <div className="activity-time">
                        {new Date(activity.start_time).toLocaleTimeString()}-
                        <strong> {activity.title}</strong>
                      </div>
                      {/* <div className="activity-description">
                      {activity.description}
                    </div> */}
                      {/* <button
                      className="dismiss-button"
                      onClick={() => dismissActivity(activity.id)}
                    >
                      Dismiss
                    </button> */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </Layout>
  );
};

export default withAuthenticationRequired(TrackActivitiesPage);
