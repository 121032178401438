// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-branding {
  background-color: #A7A466;
  color: #fff;
}

.btn-branding:disabled {
  background: #ccc;
}

.btn-branding,
.btn-branding-plain {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0 8px;
}

.btn-branding img,
.btn-branding-plain img {
  width: 150px;
  height: auto;
}

/* Responsive styles */
@media (max-width: 600px) {
  .btn-branding,
  .btn-branding-plain {
    font-size: 0.9rem;
  }

  .btn-branding img,
  .btn-branding-plain img {
    width: 100px;
  }
}

@media (min-width: 601px) {
  .btn-branding,
  .btn-branding-plain {
    font-size: 1.5rem;
  }

  .btn-branding img,
  .btn-branding-plain img {
    width: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,aAAa;AACf;;AAEA;;EAEE,YAAY;EACZ,YAAY;AACd;;AAEA,sBAAsB;AACtB;EACE;;IAEE,iBAAiB;EACnB;;EAEA;;IAEE,YAAY;EACd;AACF;;AAEA;EACE;;IAEE,iBAAiB;EACnB;;EAEA;;IAEE,YAAY;EACd;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.btn-branding {\n  background-color: #A7A466;\n  color: #fff;\n}\n\n.btn-branding:disabled {\n  background: #ccc;\n}\n\n.btn-branding,\n.btn-branding-plain {\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 1.2rem;\n  margin: 0 8px;\n}\n\n.btn-branding img,\n.btn-branding-plain img {\n  width: 150px;\n  height: auto;\n}\n\n/* Responsive styles */\n@media (max-width: 600px) {\n  .btn-branding,\n  .btn-branding-plain {\n    font-size: 0.9rem;\n  }\n\n  .btn-branding img,\n  .btn-branding-plain img {\n    width: 100px;\n  }\n}\n\n@media (min-width: 601px) {\n  .btn-branding,\n  .btn-branding-plain {\n    font-size: 1.5rem;\n  }\n\n  .btn-branding img,\n  .btn-branding-plain img {\n    width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
