import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Layout.css";
import { useCaregiver } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";
import NotificationAlert from "./NotificationAlert";

require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Layout = ({ children }) => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  // const [clients, setClients] = useContext(ClientContext)
  const {
    selectedClient,
    setSelectedClient,
    handleClientChange,
    clients,
    setClients,
    fetchClients,
    fetchClientName,
    fetchActivities,
    activities,
    setActivities
  } = useContext(ClientContext)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  // const { contacts, setContacts } = useContext(ClientContext);
  const [caregivers, setCaregivers] = useState([]);
  const [selectedCaregiver, setSelectedCaregiver] = useState(
    localStorage.getItem("selectedCaregiver") || "",
  );
  const { caregiverId, isCaregiver } = useCaregiver();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log('clients in layout', clients)

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch(`${BASE_URL}/activities` , {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
        });
        if (response.ok) {
          const data = await response.json();
          setActivities(data);
        } else {
          console.error("Failed to fetch activities");
        }
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };
    fetchActivities();
  }, [selectedClient]);

  useEffect(() => {
    if (
        !location.pathname.includes("select-client-homepage") &&
        location.pathname.includes("/calendar") ||
        location.pathname.includes("/track-activities") ||
        location.pathname.includes("/contacts") ||
        location.pathname.includes("/call")
    ) {
      if (location.pathname.includes("/calendar")) {
        navigate(`/calendar/${selectedClient}`, {
          state: { isCaregiver: true },
        });
      } else if (location.pathname.includes("/track-activities")) {
        navigate(`/track-activities/${selectedClient}`, {
          state: { isCaregiver: true },
        });
      } else if (location.pathname.includes("/contacts")) {
        navigate(`/contacts/${selectedClient}`, {
          state: { isCaregiver: true },
        });
      } else if (location.pathname.includes("/call")) {
        navigate(`/call/${selectedClient}`, {
          state: { isCaregiver: true },
        });
      }
    }
  }, [selectedClient, location.pathname]);

  // const fetchActivities = async (clientId) => {
  //   try {
  //     const response = await fetch(
  //       `${BASE_URL}/clients/${clientId}/activities`, {
  //         headers: {
  //           'Authorization': 'Bearer ' + localStorage.getItem('token'),
  //         }
  //     });
  //     if (response.ok) {
  //       const data = await response.json();
  //       navigate(`/calendar/${clientId}`, {
  //         state: { isCaregiver: true, activities: data },
  //       });
  //     } else {
  //       console.error("Failed to fetch activities for client");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching activities for client:", error);
  //   }
  // };

  useEffect(() => {
    const fetchCaregivers = async () => {
      let url = `${BASE_URL}/caregivers?user_id=${localStorage.getItem("caregiverId")}`;
      if (localStorage.getItem('isSuperAdmin')) {
        url = `${BASE_URL}/caregivers`
      }
      try {
        const response = await fetch(
          url, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
          });
        if (response.ok) {
          const data = await response.json();
          setCaregivers(data);
        } else {
          console.error("Failed to fetch caregivers");
        }
      } catch (error) {
        console.error("Error fetching caregivers", error);
      }
    };

    if (localStorage.getItem('isSuperAdmin') || localStorage.getItem('isAdmin')) {
      fetchCaregivers();
    }
  }, [caregiverId, setCaregivers]);

  const handleHomepageClick = () => {
    setSelectedClient("");
    localStorage.removeItem("selectedClient");
    navigate("../select-client-homepage", {
      state: { caregiverId: localStorage.getItem("caregiverId") },
    });
  };

  const handleCaregiverChange = (e) => {
    const caregiverId = e.target.value;
    if (caregiverId == 'create') {
      window.location.href = '/add-caregiver'
      return
    }
    setSelectedCaregiver(caregiverId);
    localStorage.setItem("selectedCaregiver", caregiverId);
  };

  const handleClientScheduleClick = () => {
    if (selectedClient) {
      navigate(`/calendar/${selectedClient}`, { state: { isCaregiver: true } });
    }
  };

  const handleTrackActivitiesClick = () => {
    if (selectedClient) {
      navigate(`/track-activities/${selectedClient}`, {
        state: { isCaregiver: true },
      });
    }
  };

  const handleCallListsCLick = () => {
    if (selectedClient) {
      navigate(`/contacts/${selectedClient}`, { state: { isCaregiver: true } });
    }
  };

  const handleCallClick= () => {
    if (selectedClient) {
      navigate(`/call/${selectedClient}`, { state: { isCaregiver: true } });
    }
  }
  return (
    <div className="outer-container">
      <header className="header">
        <button className="logo-button" onClick={handleHomepageClick}>
          <img src="/images/Compass-Care_White.png" alt="Logo" style={{ marginLeft: '10px', width: '200px' }} />
        </button>
        <div className="client-selector row">
          <select value={selectedClient} onChange={handleClientChange} style={{ marginLeft: '50px '}}>
            <option value="" disabled>
              Select a client
            </option>
            { localStorage.getItem('isSuperAdmin') ? (<option value="create">--- Create new client ---</option>) : (null) }
            {clients.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
          { (localStorage.getItem('isSuperAdmin') || localStorage.getItem('isAdmin')) ?
          (<select defaultValue={selectedCaregiver} onChange={handleCaregiverChange}>
            <option value="" disabled>
              Select a caregiver
            </option>
            { localStorage.getItem('isAdmin') ? (<option value="create">--- Create new caregiver ---</option>) : (null) }
            {caregivers.map((caregiver) => (
              <option key={caregiver.id} value={caregiver.id}>
                {caregiver.name}
              </option>
            ))}
          </select>)
          : (null) }
        </div>
        <button className="btn-branding" style={{ width: "150px" }} onClick={(ev) => {
                ev.preventDefault();
                logout({ logoutParams: { returnTo: window.location.origin + '/login' } });
                localStorage.clear();
            }}>
              Log Out
        </button>
        <div className="notification-icon" onClick={toggleDropdown}>
          <NotificationAlert
            activities={activities}
            isDropdownVisible={isDropdownVisible}
          />
        </div>
      </header>
      <div className="main-content-container">
        <div className="left-nav">
          <ul className="sidebar-nav">
            <li onClick={handleClientScheduleClick}>Schedule</li>
            <li onClick={handleCallListsCLick}>Call lists</li>
            <li onClick={handleTrackActivitiesClick}>Track activities</li>
            <li onClick={handleCallClick}>Video Call</li>
          </ul>
        </div>
        <div className="main-content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
