import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCaregiver } from "./CaregiverContext";
require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [contacts, setContacts] = useState({});
  const [activities, setActivities] = useState([]);
  const [clientName, setClientName] = useState("")
  const [selectedClient, setSelectedClient] = useState(
    localStorage.getItem("selectedClient") || "",
  );
  const { caregiverId } = useCaregiver();
  const [clients, setClients] = useState([]);

  const handleClientChange = (e) => {
    const clientId = e.target.value;
    if (clientId == 'create') {
      window.location.href = '/add-client'
      return
    }
    setSelectedClient(clientId);
    localStorage.setItem("selectedClient", clientId); // Save to localStorage
    fetchClientName(clientId);
  };

  const fetchActivities = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/clients/${selectedClient}/activities`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
        });
      if (response.ok) {
        const data = await response.json();
        const formattedActivities = data.map((activity) => ({
          ...activity,
          start: new Date(activity.start_time),
          end: new Date(activity.end_time),
          extendedProps: {
            event_state: activity.event_state, // Include event_state in extendedProps
          },
        }));
        setActivities(formattedActivities);

        // Check for reminders
        formattedActivities.forEach((activity) => {
          if (activity.reminder_enabled) {
            activity.reminder_times.forEach((time) => {
              const reminderTime = new Date(
                new Date(activity.start).getTime() - time * 60000,
              );
              const now = new Date();
              if (reminderTime > now) {
                const timeout = reminderTime - now;
                setTimeout(() => {
                  alert(
                    `Reminder: ${activity.title} starts in ${time} minutes.`,
                  );
                }, timeout);
              }
            });
          }
        });
      } else {
        console.error("Failed to fetch activities for client");
      }
    } catch (error) {
      console.error("Error fetching activities for client:", error);
    }
  };

   // Fetch clients associated with the logged-in caregiver
   const fetchClients = async () => {
    console.log("fetching...");
    try {
      let url = `${BASE_URL}/caregivers/${caregiverId}/clients`
      if (localStorage.getItem('isSuperAdmin') == 'true') {
        url = `${BASE_URL}/clients`
      }
      const response = await fetch(
        url, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
      });
      if (response.ok) {
        const data = await response.json();
        setClients(data); // Set the clients state here
        console.log("set clients data", data);
      } else {
        console.error("Failed to fetch clients for caregiver");
      }
    } catch (error) {
      console.error("Error fetching clients for caregiver:", error);
    }
  };



  const fetchContacts = async (clientId) => {
    if (!clientId) return; // Skip fetching if no client is selected
    try {
      const response = await fetch(
        `${BASE_URL}/contacts?caregiverId=${caregiverId}&clientId=${clientId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
      });
      if (response.ok) {
        const data = await response.json();
        console.log("Contacts data:", data); // Log data to inspect its structure

        // Ensure contact_type is processed correctly
        if (Array.isArray(data)) {
          data.forEach((contact) => {
            if (typeof contact.contact_type === "string") {
              contact.contact_type = JSON.parse(contact.contact_type);
            }
          });
          setContacts((prevContacts) => ({
            ...prevContacts,
            [clientId]: {
              regular: data.filter((contact) =>
                contact.contact_type.includes("regular"),
              ),
              emergency: data.filter((contact) =>
                contact.contact_type.includes("emergency"),
              ),
            },
          }));
        } else {
          console.error("Contacts data is not an array:", data);
          setContacts((prevContacts) => ({
            ...prevContacts,
            [clientId]: {
              regular: [],
              emergency: [],
            },
          }));
        }
      } else {
        console.error("Failed to fetch contacts for client");
        setContacts((prevContacts) => ({
          ...prevContacts,
          [clientId]: {
            regular: [],
            emergency: [],
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching contacts for client:", error);
      setContacts((prevContacts) => ({
        ...prevContacts,
        [clientId]: {
          regular: [],
          emergency: [],
        },
      }));
    }
  };

  const fetchClientName = async (clientId) => {
    try {
      let url = `${BASE_URL}/caregivers/${caregiverId}/clients`
      if (localStorage.getItem('isSuperAdmin') == 'true') {
        url = `${BASE_URL}/clients`
      }
      const response = await fetch(
        url, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
      });
      if (response.ok) {
        const data = await response.json();
        const client = data.find(
          (client) => client.id === parseInt(clientId),
        );
        if (client) {
          setClientName(client.name);
        } else {
          console.error("Client not found");
        }
      } else {
        console.error("Failed to fetch client name");
      }
    } catch (error) {
      console.error("Error fetching client name:", error);
    }
  };

  // useEffect(() => {
  //   const savedClient = localStorage.getItem("selectedClient");
  //   if (savedClient) {
  //     setSelectedClient(savedClient);
  //   }
  //   if (caregiverId) {
  //     fetchClients();
  //   }
  // }, []);

  useEffect(() => {
    const savedClient = localStorage.getItem("selectedClient");
    if (savedClient) {
      setSelectedClient(savedClient);
      fetchClientName(savedClient);
      fetchContacts(savedClient);
      console.log(savedClient)
    }
    if (caregiverId) {
      console.log("ID")
      console.log(caregiverId)
      fetchClients();
    }
  }, [caregiverId]);

  useEffect(() => {
    if (selectedClient) {
      fetchClientName(selectedClient);
      fetchContacts(selectedClient);
    }
  }, [selectedClient]);;

  return (
    <ClientContext.Provider
      value={{
        selectedClient,
        setSelectedClient,
        handleClientChange,
        clients,
        setClients,
        contacts,
        setContacts, 
        fetchClientName,
        clientName,
        fetchClients,
        fetchActivities,
        activities,
        setActivities,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
